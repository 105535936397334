import React from 'react'
import get from 'lodash/get'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import HPAccordion from '../accordion/accordion'
/**
 * Declares basic functionality for Modals
 * @extends React
 */
class Table extends React.Component {
  constructor(props) {
    super(props);    
    const node = this.props.node
    let contentfulTable = contentfulMapToLocalized(node.fields)
    // contentfulAccordeon = contentfulMapToLocalized(contentfulAccordeon)
    this.rows = {
      header: [],
      footer: [],
      regular: []
    };
    contentfulTable.tableRows.forEach(( item, index ) => {
      let tRow = contentfulMapToLocalized(item.fields)
      let rowCells = []
      tRow.tableCells.forEach(( item, index ) => {
        let tCell = contentfulMapToLocalized(item.fields)
        rowCells.push(tCell.content)
      })
      switch (tRow.type) {
        case 'header': 
          this.rows.header.push(rowCells)
          break
        case 'footer': 
          this.rows.footer.push(rowCells)
          break
        default: 
          this.rows.regular.push(rowCells)
      }
    })
    // this.className = get(contentfulAccordeon, 'extraAttributes.className', '')
  }
  
  render() {
    return (
      <table className="table contentful-table">
        <thead>
          {
            this.rows.header.map((row, index) => {
              return (
                <tr key={index}>
                  { row.map((cell, index) => (<th key={index}>{cell}</th>)) }
                </tr>
              )
            }
          )}
        </thead>
        <tbody>
          {
            this.rows.regular.map((row, index) => {
              return (
                <tr key={index}>
                  { row.map((cell, index) => (<td key={index}>{cell}</td>)) }
                </tr>
              )
            })
          }
        </tbody>
        <tfoot>
          {
            this.rows.footer.map((row, index) => {
              return (
                <tr key={index}>
                  { row.map((cell, index) => (<td key={index}>{cell}</td>)) }
                </tr>
              )
            })
          }
        </tfoot>
      </table>
    )
  }
}

export default Table